.produit-container {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  min-height: 400px;
  margin: 20px 0 20px 0;
  position: relative;
  overflow: hidden;
  // background-color: $bg-color
  &.comingsoon {
    pointer-events: none; }
  &:hover > .produit-image-container > .produit-image {
    // background-color: $hover-color
    transform: scale(1.05); }
  // &:hover > .produit-image-container > .produit-bg
  //   opacity: 1
  //   transform: scale(0.9)
  &:hover > .produit-footer > .produit-footer-titre {
    color: #0B9CD2; }
  &:hover > .produit-footer > .produit-footer-titre > span {
    color: #0B9CD2; }
  @media screen and(max-width: 650px) {
    width: 160px;
    min-height: 300px; }

  .produit-image-container {
    position: relative;
    width: 300px;
    height: 300px;
    // font-family: futura
    text-align: center;
    @media screen and(max-width: 650px) {
      width: 170px;
      height: 170px; }
    &:hover > .produit-price {
      // background-color: #ffffff
      color: black;
      transition: transform 0.5s; }

    .produit-price {
      color: black;
      line-height: 40px;
      margin-top: 50px;
      position: absolute;
      width: 100%;
      z-index: 9999;
      // font-family: futura
      font-weight: bold;
      font-size: 25px;
      pointer-events: none;
      transition: all 0.5s; }
    .produit-image {
      position: relative;
      height: 100%;
      width: 100%;
      transition: all 0.5s;
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */;
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */;
      transform: translateZ(0);
 }      // background-color: $bg-color
    // .produit-bg
    //   opacity: 0
    //   position: absolute
    //   height: 100%
    //   width: 100%
    //   transition: all 0.7s
    //   background-color: #f1fbff
 }    //   border-radius: 50%

  .produit-footer {
    // height: 100px
    margin-top: 10px;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: left;
    box-sizing: border-box;

    .produit-footer-titre {
      width: 100%;
      color: black;
      font-weight: bold;
      font-size: 1.5rem;
      text-transform: none;
      text-decoration: none;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      transition: color 0.5s;
      span {
        transition: color 2s;
        color: black; }
      @media screen and(max-width: 650px) {
        font-size: 1.2rem; } }

    .produit-footer-line {
      width: 100%;
      letter-spacing: 4px;
      color: black;
      font-family: futura;
      font-size: 1rem;
      text-transform: none;
      text-decoration: none;
      font-weight: bold;
      margin: 3px 0 3px 0;
      @media screen and(max-width: 650px) {
        font-size: 0.9rem; }
      &.price {
        letter-spacing: 0px;
        font-weight: normal;
        span {
          padding: 3px;
          border: 1px solid black;
          border-radius: 3px;
          transition: all 0.3s ease-in-out;
          // &:hover
          //   background-color: #317ab7
          //   color: white
 }          //   border: 1px solid #317ab7
        .comingsoon {
          background-color: #317ab7;
          color: white; } } }

    .produit-footer-bouton {
      padding-top: 5px;
      margin: auto;
      overflow: hidden;
      width: 40%;
      // height: 100px
      // font-family: futura
 } } }      // background-color: white
