.App {
  text-align: center; }

.NotFound-page {
  background-color: #282c34;
  width: 100%;
  height: 100vh;
  .notfound-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    line-height: 50vh;
    background: url("../img-js/apropos-pic.jpg") no-repeat center;
    background-size: cover;
    filter: brightness(50%); }

  .notfound-banner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    line-height: 15px;
    margin-top: 20%;
    .notfound-logo {
      height: 15vh;
      pointer-events: none; }
    .notfound-message {
      margin-top: 40px;
      text-align: center;
      width: 100%;
      position: absolute;
      // font-family: futura
      font-size: calc(10px + 2vmin);
      color: white;
      text-transform: none;
      text-decoration: none; } } }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
