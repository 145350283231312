.footer-container {
  font-size: 12px;
  color: #222222;
  text-align: center;
  background-color: white;
  padding-top: 50px;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  div {
    width: 33vw; }
  @media screen and ( max-width: 1070px ) {
    div {
      width: 100vw; } }

  .footer-item {
    .footer-link {
      color: #222222;
      text-decoration: none; } } }
