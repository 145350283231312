$bg-color: #ffffff;

.boutique-page {
  // height: 100vh
  // max-height: 100vh
  //---------- BUTTON STYLE
  .boutique-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .boutique-newsletter {
      position: relative;
      padding: 5px 12px;
      height: 60px;
      width: 180px;
      outline: none;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      background-color: #ffffff;
      border: 3px solid #0092C8;
      border-radius: 5px;
      color: #0092C8;
      z-index: 0;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
      // cursor: url("../img-js/cursors/a-bottle-cursor-link.ico"), auto
      span {
        color: #0092C8;
        // font-family: lemon_milkbold
        font-size: 13px;
        &:hover {
          animation: rotate 0.7s ease-in-out both;
          span {
            animation: storm 0.7s ease-in-out both;
            animation-delay: 0.06s; } }
 } } }        //---------- BUTTON STYLE

  // MOBILE NEWSLETTER POPUP SIZE
  @media screen and ( max-width: 600px ) {
    .rodal-dialog {
      margin-top: 90px;
      width: 330px !important;
      height: 600px !important;
      .apropos-news-iframe {
        width: 100%;
        height: 600px; } } }
  // DESKTOP NEWSLETTER POPUP SIZE
  @media screen and ( min-width: 600px ) {
    .rodal-dialog {
      width: 600px !important;
      height: 500px !important;
      .apropos-news-iframe {
        width: 100%;
        height: 500px; } } }

  .boutique-content {
    text-align: center;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 95vh; }

  // .boutique-left-panel
  //   height: 100%
  //   margin-top: 70px
  //   // min-width: 200px
  //   // background-color: rgba(179, 179, 179, 0.178)
  //   filter: drop-shadow(10px 8px 12px rgba(179, 179, 179, 0.178))
  //   display: inline-block
  //   position: fixed
  //   width: 20%
  //   @media screen and ( max-width: 822px )
  //     width: 0%
  //     visibility: hidden
  //   .boutique-filters-container
  //     display: block
  //     text-align: left
  //     margin-left: 25%
  //   .boutique-button
  //     height: 40px
  //     width: 100px
  //     margin-bottom: 25px

  //     border-radius: 0px
  //     border: 0px
  //     background-color: #317bb7
  //     color: white

  //     // font-family: futura
  //     font-size: 12px
  //     font-weight: bold
  //     text-transform: uppercase
  //     line-height: 40px
  //     text-align: center
  //     &:hover
  //       background-color: rgb(36, 90, 134)

  .boutique-items-container {
    top: 20px;
    background-color: $bg-color;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    width: 100%;
    max-width: 1100px;
    .boutique-item {
      margin: 2px; } } }

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0); }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0); }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0); }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0); } }

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0); }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0); }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0); }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0); }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0); } }
