
.mentionslegales-header {
  background-color: white;
  width: 100%;
  min-height: 88vh;
  font-size: calc(10px + 2vmin);
  color: black;
  .mentionslegales-content-container {
    margin-top: 70px;
    .apropos-block {
      margin-left: 4%;
      text-align: left;
      width: 44%;
      min-width: 390px;
      display: inline-block;
      vertical-align: top;
      @media screen and ( max-width: 847px ) {
        width: 90%; }
      .apropos-title {
        margin-bottom: 10px;
        text-transform: uppercase; }
      .apropos-text {
        // font-family: futura
        font-size: 16px; } } } }
