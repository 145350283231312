.radio-page {
  text-align: center;
  // min-height: 100vh
  .radio-header {
    padding-top: 30vh;
    display: inline-block;
    // flex-direction: column
    justify-content: top;
    width: 98%;
    // margin-left: 1%
    height: 65vh;
    background-color: white;
    width: 100%;
    font-size: calc(10px + 2vmin);
    color: black;
    @media screen and ( max-width: 1070px ) {
      height: 60vh; }
    .drop-title {
      margin: 0px;
      margin-bottom: 60px;
      color: #317ab7;
      .redtext {
        color: #ea332b; } }
    .radio-player-section {
      display: inline-flex;
      margin-left: -150px;
      margin-bottom: 150px;

      .button {
        outline: transparent;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 74px;
        margin-left: -70px;

        border-color: transparent transparent transparent #317ab7;
        transition: .4s all ease;
        cursor: pointer;
        user-select: none !important;

        &.paused {
          border-style: solid;
          border-width: 37px 0 37px 60px;
          border-color: transparent transparent transparent #ea332b;
          &:hover {
            border-color: transparent transparent transparent rgb(185, 40, 33); } }

        &.playing {
          border-style: double;
          border-width: 0px 0 0px 60px;
          border-color: transparent transparent transparent #317ab7;
          &:hover {
            border-color: transparent transparent transparent rgb(37, 97, 146); } } }

      .scrollingtext {
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        width: 250px;
        span {
          display: inline-block;
          padding-left: 100%;
          // padding-top: 9%
          animation: scrollingtext 5s linear infinite; } } }

    .radio-soundcloud-section {
      width: 70vw;
      // display: inline-
      margin-left: 15vw;
      .soundcloudplayer {
        margin-top: 50px; } } } }

@keyframes scrollingtext {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }
