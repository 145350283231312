
.accueil {
  background-color: white;
  // width: 100vw
  color: black;
  .accueil-part {
    // max-height: 90vh
    // width: 100%
    // height: 100%
    // overflow: hidden
    // position: absolute
    .accueil-slideshow-container {
      height: 88%;
      overflow: hidden;
      padding-left: 1%;
      padding-right: 1%;
      background-color: white;
      padding-top: 75px;
      .slick-slide img {
        pointer-events: none;
        width: 100% !important;
        height: 88vh;
        object-fit: cover;
        margin: auto;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0);
        -webkit-transform: translateZ(0);
        transform: translateZ(0); }
      .slick-arrow {
 }        // width: 400px
      .slick-prev {
        left: 20px;
        z-index: 99; }
      .slick-next {
        right: 20px; } } }

  .rodal-mask {
    background: rgba(0, 0, 0, 0.8) !important; }
  .rodal-dialog {
    background-color: white;
    padding: 10px; }

  .accueil-rodal-titre {
    line-height: 40px;
    text-align: center;
    padding: 0 10px 0 10px; }
  .accueil-rodal-button {
    border: 0px;
    width: 48%;
    // font-family: lemon_milkregular
    text-transform: uppercase;
    margin: 1%;
    transition: background-color 0.3s;
    color: black;
    vertical-align: middle;
    &.yes {
      cursor: pointer;
      transition: all 0.3s;
      background-color: #317ab7;
      color: white !important;
      &:hover {
        background-color: rgb(36, 100, 151);
        color: white !important;
        transition: all 0.3s; } }
    &.no {
      cursor: pointer;
      &:hover {
        background-color: #ea332b;
        color: white !important;
        transition: all 0.3s; } }
    &:focus {
      outline: none; } }

  @media screen and ( min-width: 600px ) {
    .rodal-dialog {
      height: 250px !important;
      width: 580px !important;
      border-radius: 0px;
      .accueil-rodal-button {
        border: 0px;
        width: 48%;
        min-height: 100px; } } }
  @media screen and ( max-width: 600px ) {
    .rodal-dialog {
      height: 270px !important;
      width: 90vw !important;
      .accueil-rodal-button {
        font-size: 16px;
        padding: 25px;
        min-height: 120px; } } } }

// .accueil-musicOn
//   // cursor: url("../img-js/cursors/a-bottle-cursor-link.ico"), auto

// .accueil-musicOff
//   // cursor: url("../img-js/cursors/a-bottle-cursor-link.ico"), auto
//   // clip-path: inset(0px 15px 0px 0px)
