//#ffff00
//#c1e8ff

$bg-color: black;
$hover-color: #c1e8ff;
$hover-rouge: #EA332B;
$hover-bleu: #317AB7;
$hover-vert: #00B284;
$hover-jaune: #FFD649;

.lieu-container-inline-block {
  display: inline-block;
  padding: 20px;

  .lieu-container {
    border-radius: 5px;
    box-shadow: 6px 6px 20px #d3d3d3;
    // background-color: #f0f0f049
    width: 250px;
    height: 150px;
    padding-left: .5em;
    padding-right: .5em;
    text-align: initial;
    display: flex;
    flex-direction: column;
    // justify-content: center
    // cursor: url("../img-js/cursors/a-bottle-cursor-link.ico"), auto
    // position: relative
    color: black;
    overflow: hidden;
    white-space: normal;
    transition: background-color 0.3s ease;
    * {
      font-family: futura; }
    .lieu-titre {
      // margin-top: 20%
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      font-family: Sausage;
 }      // letter-spacing: -1px
    .lieu-adresse,
    .lieu-codepostal,
    .lieu-pays {
      font-size: 1rem; }

    span {
      display: block; }
    &:hover {
      color: white;
      background-color: $hover-color; } }

  .rouge:hover {
    background-color: $hover-rouge; }
  .bleu:hover {
    background-color: $hover-bleu; }
  .vert:hover {
    background-color: $hover-vert; }
  .jaune:hover {
    background-color: $hover-jaune; } }
