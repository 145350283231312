.navbar-sticky {
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 9999999;
  filter: drop-shadow(10px 8px 12px rgba(179, 179, 179, 0.178)); }

.navbar-relative {
  text-align: center;
  position: relative;
  top: 0; }

//MOBILE NAVBAR
@media screen and ( max-width: 940px ) {
  .navbar.menuOpened {
    transition: all 0.3s;
    filter: drop-shadow(10px 8px 12px #b3b3b360); }
  .navbar {
    height: 70px;
    transition: all 0.3s;
    overflow: hidden;
    width: 100vw;
    vertical-align: middle;
    z-index: 999;
    background-color: white;
    // text-align: center

    .navbar-burger-menu-button {
      position: absolute;
      padding: 15px;
      margin-top: 15px;
      margin-left: 15px;
      left: 0px;
      border: none;
      background: none;
      outline: none;
      transition: all 0.3s;
      cursor: pointer;
      transform: scale(2);
      &:hover {
        color: black;
        transition: all 0.3s;
        transform: scale(1.8); }
      &.menuOpened {
        transition: all 0.3s;
        transform: rotate(180deg) scale(2) translateY(3px); } }

    .navbar-logo-container {
      height: 55px;
      display: inline-block;
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 15px;
      .navbar-logo {
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        color: #0B9CD2;
        font-size: 37px; } }

    .navbar-menu-container {
      width: 100%;
      display: block;
      text-align: center;
      background-color: white;
      text-decoration: none;
      .navbar-menu {
        width: 80%;
        margin: auto;
        line-height: 40px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        color: black;
        vertical-align: middle;
        cursor: pointer; } }

    .navbar-social-icons-container {
      width: 200px;
      display: inline-flex;
      text-align: center;
      line-height: 60px;
      background-color: white;
      a {
        width: 100%;
        .navbar-social-icon {
          width: 100%;
          margin: auto;
          color: grey;
          transition: all 0.3s;
          &:hover {
            color: black;
            transition: all 0.3s;
            transform: scale(1.4); } } } } } }

//DESKTOP NAVBAR
@media screen and ( min-width: 940px ) {
  .navbar {
    height: 70px;
    width: 100vw;
    vertical-align: middle;
    // z-index: 999
    background-color: white;
    text-align: left;
    transition: all 0.3s;

    .navbar-burger-menu-button {
      visibility: hidden;
      position: absolute; }

    .navbar-logo-container {
      z-index: 999;
      height: 100%;
      display: inline-block;
      margin-left: 14px;
      margin-right: 14px;
      margin-top: 15px;
      .navbar-logo {
        // left: 0
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        color: #0B9CD2;
        font-size: 37px;
 } }        // margin-top: 14px

    .navbar-menu {
      margin: 0px;
      line-height: 40px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 15px;
      padding-right: 15px;
      display: inline-block;
      font-size: 20px;
      text-transform: none;
      text-decoration: none;
      text-transform: uppercase;
      color: black;
      vertical-align: middle;
      cursor: pointer; }

    .navbar-social-icons-container {
      display: inline-flex;
      float: right;
      line-height: 70px;
      margin-right: 25px;
      .navbar-social-icon {
        padding-left: 7px;
        padding-right: 7px;
        color: grey;
        transition: all 0.3s;
        &:hover {
          color: black;
          transition: all 0.3s;
          transform: scale(1.4); } } } } }

// MENUS

.boutique {
  transition: all 0.3s;
  color: black;
  &:hover {
    background-color: #ea332b;
    color: white !important;
    transition: all 0.3s; }
  &.boutique_selected {
    background-color: #ea332b !important;
    color: white !important; } }

.drop {
  transition: all 0.3s;
  color: black;
  &:hover {
    background-color: #ffd649;
    color: white !important;
    transition: all 0.3s; }
  &.drop_selected {
    background-color: #ffd649 !important;
    color: white !important; } }

.poseyradio {
  transition: all 0.3s;
  color: black;
  &:hover {
    background-color: #317ab7;
    color: white !important;
    transition: all 0.3s; }
  &.poseyradio_selected {
    background-color: #317ab7 !important;
    color: white !important; } }

.noustrouver {
  transition: all 0.3s;
  color: black;
  &:hover {
    background-color: #00b284;
    color: white !important;
    transition: all 0.3s; }
  &.noustrouver_selected {
    background-color: #00b284 !important;
    color: white !important; } }

.apropos {
  transition: all 0.3s;
  color: black;
  &:hover {
    background-color: #ff943c;
    color: white !important;
    transition: all 0.3s; }
  &.apropos_selected {
    background-color: #ff943c !important;
    color: white !important; } }
