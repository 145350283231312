.pageproduit-page {
  text-align: center;
  // min-height: 100vh
  display: flex;
  flex-direction: column;
  // max-height: 100vh
  // overflow: hidden

  .pageproduit-header {
    margin-top: 70px;
    padding: 2%;
    // max-height: 680px
    // overflow: hidden
    // display: flex
    // flex-direction: column
    // justify-content: center
    // margin-left: 1%
    // height: 90vh
    background-color: white;
    font-size: calc(10px + 2vmin);
    color: black;
    min-height: 80vh;
    //---------- BUTTON STYLE
    .pageproduit-livraison {
      position: relative;
      padding: 5px 12px;
      height: 60px;
      width: 180px;
      outline: none;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      background-color: #ffffff;
      border: 3px solid #0092C8;
      border-radius: 5px;
      color: #0092C8;
      z-index: 0;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
      // cursor: url("../img-js/cursors/a-bottle-cursor-link.ico"), auto

      span {
        color: #0092C8;
        // font-family: lemon_milkbold
        font-size: 13px; }
      &:hover {
        animation: rotate 0.7s ease-in-out both;
        span {
          animation: storm 0.7s ease-in-out both;
          animation-delay: 0.06s; } }
 } } }      //---------- BUTTON STYLE

iframe:nth-child(2) {
  display: none !important; }

.pageproduit-menu {
  display: inline-flex;
  height: 60px;
  .pageproduit-retour {
    position: absolute;
    left: 30px;
    color: rgba(0,0,0,0.6) !important;
    text-align: left;
    transition: all 1s;
    font-size: 25px;
    line-height: 60px;
    // margin: 30px 50px 0px 50px !important

    // @media (max-width: 1050px)
    //   margin: 30px 50px 0px 50px !important

    .pageproduit-boutonRetour:hover {
      color: rgba(0,0,0,0.4) !important; }
    .pageproduit-boutonRetour:focus {
      color: rgba(0,0,0,0.4) !important; }
    .icon {
      margin-right: 5px;
      color: rgba(0,0,0,0.2) !important; } }

 }  //---------- BUTTON STYLE

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0); }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0); }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0); }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0); } }

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0); }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0); }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0); }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0); }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0); } }

.has-image {
  display: inline-block;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  @media screen and ( max-width:700px) {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .shopify-buy__select-icon {
    display: none; }
  .shopify-buy__product__title {
    margin-bottom: 0 !important; }
  > * {
    width: 45vw;
    display: inline-block;
    overflow: auto;
    padding-left: 45vw;
    text-align: left;
    @media screen and ( max-width:700px) {
      display: block;
      padding-left: 0;
      width: 80vw; }
    span {
      font-size: 16px; }
    .shopify-buy__product__actual-price {
      font-size: 18px; }
    .visuallyhidden {
      display: none; }
    .shopify-buy__option-select-wrapper {
      display: flex;
      justify-content: center;
      .shopify-buy__option-select__select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 60%;
        height: 60px;
        font-size: 18px;
        padding: 5px;
        margin: 15px 0 0 0;
        background-color: #0093c8c9;
        border-radius: 10px;
        border: none;
        color: white; } } }
  .shopify-buy__btn-wrapper {
    text-align: center;
    .shopify-buy__btn {
      background-color: #FF93B9;
      color: white;
      border: none;
      height: 60px;
      width: 150px;
      cursor: pointer;
      margin: 30px 0 30px 0;
      border-radius: 40px;
      font-size: 20px;
      transition: background-color 0.3s;
      &:hover {
        background-color: #ff76a6; } }
    .shopify-buy__btn-disabled {
      font-size: 0px;
      &:hover {
        background-color: #ff76a6;
        cursor: wait; }
      &::after {
        font-size: 20px;
        content: 'Rupture'; } } }
  .shopify-buy__product-description {
    font-size: 20px; }
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0;
    @media screen and ( max-width:700px) {
      position: relative;
      width: 80vw; }
    .main-image-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        object-fit: cover;
        width: 90%;
        max-width: 500px;
        max-height: 500px; }
      .carousel-button {
        width: 5%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: transparent;
        background-color: transparent;
        border: transparent;
        cursor: pointer;
        transition: transform 0.1s;
        border-radius: 20%;
        &:focus {
          outline: none; }
        &:active {
          transform: scale(0.9); }
        &:active + .carousel-button-arrow {
          color: white; }
        .carousel-button-arrow {
          position: absolute;
          // transform: translateX(-13px) translateY(1px)
          height: 20px; }
        &.carousel-button--previous {
          .carousel-button-arrow {
            transform: rotate(180deg); } } } } }

  &:not(:first-child) {
    display: none !important; } }

.shopSuggestionDiv {
  height: 100%;
  .shopSuggestionsTitle {
    margin-top: 100px;
    padding: 2%;
    // border-top: 5px solid $isaac-color
    span {
      font-size: 24px;
      cursor: default; } }
  .slick-list {
    max-height: 440px;
    overflow: hidden; }

  .boutonRetour:hover {
    color: rgba(0,0,0,0.4) !important; }
  .boutonRetour:focus {
    color: rgba(0,0,0,0.4) !important; }
  .icon {
    margin-right: 5px;
    color: rgba(0,0,0,0.2) !important; } }
