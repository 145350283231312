.apropos-page {
  text-align: center;
  height: 100%;
  // min-height: calc(100vh - 70px)

  .App-header {
    // height: 85vh
    background-color: white;
    width: 100%;
    font-size: calc(10px + 2vmin);
    color: black; }
  .apropos-banner {
    margin-top: 70px;
    width: 98%;
    margin-left: 1%;
    height: 350px;
    // background: url("../img-js/apropos-pic.jpg") no-repeat center
    background-size: cover;
    display: flex;
    .rosey-logo {
      margin: auto;
      pointer-events: none;
      color: #0B9CD2;
      font-size: 190px;
      @media screen and ( max-width: 600px ) {
        font-size: 90px; } } }
  .apropos-content-container {

    .apropos-block {
      margin-left: 5%;
      margin-bottom: 4%;
      text-align: left;
      width: 45%;
      min-width: 350px;
      display: inline-block;
      vertical-align: top;
      .apropos-title {
        margin-bottom: 10px; }
      .apropos-text {
        font-family: futura;
        font-size: 20px; } } }

  // MOBILE NEWSLETTER POPUP SIZE
  @media screen and ( max-width: 600px ) {
    .rodal-dialog {
      margin-top: 90px;
      width: 330px !important;
      height: 600px !important;
      .apropos-news-iframe {
        width: 100%;
        height: 600px; } } }
  // DESKTOP NEWSLETTER POPUP SIZE
  @media screen and ( min-width: 600px ) {
    .rodal-dialog {
      width: 600px !important;
      height: 500px !important;
      .apropos-news-iframe {
        width: 100%;
        height: 500px; } } } }

.apropos-button {
  height: 40px;
  width: 12em;
  margin-bottom: 25px;

  border-radius: 0px;
  border: 0px;
  background-color: #317bb7;
  color: white;

  // font-family: futura
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    background-color: rgb(36, 90, 134); } }

.contact-input {
  height: 25px;
  width: 200px;
  margin-bottom: 5px;
 }  // font-family: futura

.contact-sujet {
  height: 25px;
  width: 200px;
  margin-bottom: 5px;
  border: 1px solid #d6d6d6;
  box-shadow: none;
  background: transparent;
 }  // font-family: futura

.contact-message {
  height: 80px;
  width: 200px;
  margin-bottom: 5px;
  border: 1px solid #d6d6d6;
 }  // font-family: futura
