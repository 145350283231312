.pageproduit-rodal {
    .rodal-dialog {
        padding: 0px;
        background-color: transparent !important;
 }        // pointer-events: none
    .rodal-mask {
        background: rgba(0, 0, 0, 0.7); }
    .rodal-close {
        display: none; }

    @media screen and ( max-width: 600px ) {
        .rodal-dialog {
            margin-top: 90px;
            width: 330px !important;
            height: 330px !important; }
        .apropos-news-iframe {
            width: 100%;
            height: 330px; } }
    @media screen and ( min-width: 600px ) {
        .rodal-dialog {
            width: 600px !important;
            height: 500px !important; }
        .apropos-news-iframe {
            width: 100%;
            height: 500px; } } }
