.drop-page {
  text-align: center;
  min-height: 100vh;
  .drop-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    // margin-left: 1%
    height: 90vh;
    background-color: white;
    width: 100%;
    font-size: calc(10px + 2vmin);
    color: black; }
  .drop-title {
    margin: 0px;
    margin-bottom: 20px; } }
