.noustrouver-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .noustrouver-header {
    display: flex;
    flex-direction: column;
    // justify-content: center
    width: 98%;
    margin-top: 80px;
    background-color: white;
    font-size: calc(10px + 2vmin);
    color: black;
    .noustrouver-title {
      margin: 0px;
      margin-top: 30px; }
    .lieux-container {
      border-radius: 5px;
      background-color: #f0f0f049;
      height: 200px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin: 2%;
      align-items: left; } } }
