@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: url("./img-js/cursors/a-bottle-cursor.ico"), auto; */
}

* {
  font-family: sausage;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* label {
  cursor: url("./img-js/cursors/a-bottle-cursor.ico"), auto;
}

a {
  cursor: url("./img-js/cursors/a-bottle-cursor-link.ico"), auto;
}

button {
  cursor: url("./img-js/cursors/a-bottle-cursor-link.ico"), auto;
}

.slick-arrow {
  cursor: url("./img-js/cursors/a-bottle-cursor-link.ico"), auto;
}

input {
  cursor: url("./img-js/cursors/a-bottle-cursor-link.ico"), auto;
} */

h2 {
  font-family: sausage;
  font-size: 34px;
  text-transform: uppercase;
}

h3 {
  font-family: sausage;
  font-size: 28px;
  text-transform: uppercase;
}
h4 {
  font-family: sausage;
  font-size: 21px;
  text-transform: uppercase;
}
h5 {
  font-family: sausage;
  font-size: 16px;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Link {
  text-transform: none;
  text-decoration: none;
  /* cursor: url("./img-js/cursors/bottle-cursor-link.ico"), auto; */
}

/* POLICES */
@font-face {
  font-family: "aquagrotesque";
  src: local("AquaGrotesque"), url(./fonts/AquaGrotesque.ttf) format("truetype");
}
@font-face {
  font-family: "futura";
  src: local("FuturaLTBook"), url(./fonts/FuturaLTBook.ttf) format("truetype");
}

/* LEMON MILK */
@font-face {
  font-family: "lemon_milkbold";
  src: url("./fonts/lemonmilk-bold-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milkbold_italic";
  src: url("./fonts/lemonmilk-bolditalic-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milklight";
  src: url("./fonts/lemonmilk-light-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milklight_italic";
  src: url("./fonts/lemonmilk-lightitalic-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milkmedium";
  src: url("./fonts/lemonmilk-medium-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milkmedium_italic";
  src: url("./fonts/lemonmilk-mediumitalic-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milkregular";
  src: url("./fonts/lemonmilk-regular-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lemon_milkregular_italic";
  src: url("./fonts/lemonmilk-regularitalic-webfont.woff2") format("woff2"),
    url("./fonts/lemonmilk-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sausage";
  src: url("./fonts/Sausage.woff") format("woff"),
    url("./fonts/Sausage.woff2") format("woff2"),
    url("./fonts/Sausage.eot") format("eot"),
    url("./fonts/Sausage.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

/*
@font-face font-family: "aquagrotesque"
  src: url("../fonts/AquaGrotesque.eot")
  src: url("../fonts/AquaGrotesque.eot?#iefix") format("embedded-opentype")
  src: url("../fonts/AquaGrotesque.woff2") format("woff2")
  src: url("../fonts/AquaGrotesque.woff") format("woff")
  src: url("../fonts/AquaGrotesque.ttf") format("truetype")
  src: url("../fonts/AquaGrotesque.svg#AquaGrotesque") format("svg")
  font-weight: normal
  font-style: normal */
/*
@font-face font-family: "FuturaLTBook"
  src: url("../fonts/FuturaLTBook.eot")
  src: url("../fonts/FuturaLTBook.eot?#iefix") format("embedded-opentype")
  src: url("../fonts/FuturaLTBook.woff2") format("woff2")
  src: url("../fonts/FuturaLTBook.woff") format("woff")
  src: url("../fonts/FuturaLTBook.ttf") format("truetype")
  src: url("../fonts/FuturaLTBook.svg#FuturaLTBook") format("svg")
  font-weight: normal
  font-style: normal */
